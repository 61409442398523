<template>
    <div class="main">
        <Search :cur="cur" />
        <div class="tags">
            <div>
                <span :class="cur == 0 ? 'active' : ''" @click="cur = 0">需求</span>
                <span :class="cur == 1 ? 'active' : ''" @click="cur = 1">店铺</span>
            </div>
        </div>
        <!-- 返回导航栏 -->
        <div class="step">
            <div class="step-c" @click="toStep">
                <img src="../../assets/images/community/fh (11).png" alt="">
                <span>返回</span>
            </div>
        </div>
        <!-- banner图 -->
        <div class="banner">
            <div class="banner-c">
                <div class="portrait">
                    <img :src="$util.host +otherInfo.headPic" alt="">
                </div>
                <div class="right">
                    <div class="titName">{{otherInfo.accountKey}}</div>
                    <div class="bottom">
                        <div class="list">
                            <img src="../../assets/images/gryh.png" alt="">
                            <div class="text" v-if="otherInfo.accountType==1">个人用户</div>
                            <div class="text" v-else>企业用户</div>
                        </div>
                        <div class="list">
                            <img src="../../assets/images/serve/dw.png" alt="">
                            <div class="text">{{mapAll[otherInfo.province]}}</div>
                        </div>
                        <div class="list">
                            <img src="../../assets/images/serve/hy.png" alt="">
                            <!-- {{industryList[otherInfo.industry]}} -->
                            <div class="text">{{industry[otherInfo.industry]}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bt">
                <div class="bt-c">
                    {{needList.length}}<span>需求</span>
                </div>

            </div>
        </div>
        <!-- 内容 -->
        <div class="center">
            <div class="center-c">
                <div class="title">
                    <span :class="curs == 0 ? 'active' : ''" @click="curs = 0">需求</span>
                    <!-- <span :class="curs == 1 ? 'active' : ''" @click="curs = 1">店铺</span> -->
                </div>
                <div class="line"></div>
                <!-- 需求 -->
                <div v-if="needList.length!==0" class="classification-content">
                    <div class="litle-need">
                        <div class="litle-cell" v-for="item in needList" :key="item.id" @click="goDemand(item.id)">
                            <div class="litle-title">{{ item.name }}</div>
                            <div class="tag-box">
                                <div class="tag" v-text="item.sendUserType == 1 ? '个人用户' : '企业用户'"></div>
                                <div class="tag">{{ industry[item.sendIndustry] }}</div>
                                <div class="tag">
                                    {{ mapAll[item.sendProvince] }}{{ mapAll[item.sendCity] }}
                                </div>
                            </div>
                            <div class="litle-money">预算 ￥ {{ item.sendMoneyBudget }}</div>
                        </div>
                    </div>
                </div>
                <div class="tips" v-else>
                    <img src="../../assets/images/ts (3).png" alt="">
                    <span>用户暂无需求</span>
                </div>
                <!-- 店铺 -->
                <!-- <div v-if="curs==1" class="classification-content">
                    <div class="nice-shop">
                        @click="goShop(item.id)"
                        <div class="shop" v-for="item in goodShopDefList" :key="item.id">
                            <div class="shop-info">
                                <div class="shopName">{{ item.name }}</div>
                                <div class="shop-img">
                                    <img :src="$util.host + item.headPic" alt="" />
                                    <div v-if="item.type == 2">企</div>
                                </div>
                                <div class="classTab">
                                    <span v-for="(val,index) in item.shopClassify" :key="val.id"><span v-if="index!==0">|</span>{{ val.name }}</span>
                                </div>
                                <div class="shop-tags">
                                    <div v-show="item.shopClassify.length > 1">多类</div>
                                    <div v-show="item.shopClassify.length == 1">
                                        {{ item.shopClassify[0].name }}
                                    </div>
                                </div>
                            </div>
                            <div class="shop-num">
                                <div>服务雇主{{ item.workForUserSize }}家</div>
                                月成交量{{ item.mouthOverSize }}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Search from "../../components/search.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    name: 'QipangpangDemand',

    data() {
        return {
            curs: 0,//需求及店铺
            cur: 0, //当前下标
            id: this.$route.query.id,//用户id
            needList: [],//需求列表
            releaseTime: [
                { id: 0, title: "3天内", day: 3 },
                { id: 1, title: "一周内", day: 7 },
                { id: 2, title: "一个月内", day: 30 },
                { id: 3, title: "3个月内", day: 90 },
                { id: 4, title: "半年内", day: 180 },
                { id: 5, title: "1年内", day: 365 },
                { id: 6, title: "3年内", day: 1095 },
            ], //发布时间
            industryList: [
                { id: "A", title: "农、林、牧、渔业" },
                { id: "B", title: "采矿业," },
                { id: "C", title: "制造业" },
                { id: "D", title: "电力、热力、燃气及水生产和供应业" },
                { id: "E", title: "建筑业" },
                { id: "F", title: "批发和零售业" },
                { id: "G", title: "交通运输、仓储和邮政业" },
                { id: "H", title: "住宿和餐饮业" },
                { id: "I", title: "信息传输、软件和信息技术服务业" },
                { id: "J", title: "金融业" },
                { id: "K", title: "房地产业" },
                { id: "L", title: "租赁和商务服务业" },
                { id: "M", title: "科学研究和技术服务业" },
                { id: "N", title: "水利、环境和公共设施管理业" },
                { id: "O", title: "居民服务、修理和其他服务业" },
                { id: "P", title: "教育" },
                { id: "Q", title: "卫生和社会工作" },
                { id: "R", title: "文化、体育和娱乐业" },
                { id: "S", title: "公共管理、社会保障和社会组织" },
                { id: "T", title: "国际组织" },
            ], //行业
            industry: {
                A: "农、林、牧、渔业",
                B: "采矿业,",
                C: "制造业",
                D: "电力、热力、燃气及水生产和供应业",
                E: "建筑业",
                F: "批发和零售业",
                G: "交通运输、仓储和邮政业",
                H: "住宿和餐饮业",
                I: "信息传输、软件和信息技术服务业",
                J: "金融业",
                K: "房地产业",
                L: "租赁和商务服务业",
                M: "科学研究和技术服务业",
                N: "水利、环境和公共设施管理业",
                O: "居民服务、修理和其他服务业",
                P: "教育",
                Q: "卫生和社会工作",
                R: "文化、体育和娱乐业",
                S: "公共管理、社会保障和社会组织",
                T: "国际组织",
            }, //行业.
            shopProList: [], //省
            provinceList: [], //省
            mapAll: {},
            goodShopDefList: [],//店铺
            otherInfo: {},//基础信息
        };
    },

    mounted() {
        this.init()
        this.getProvince()
        this.getAllMap();
    },

    methods: {
        goDemand(id) {
            this.$router.push({ path: "/demand", query: { id } });
        },
        //回到上一页
        toStep() {
            this.$router.go(-1)
        },
        // 获取所有地址
        getAllMap() {
            this.$util.post("/address/allMap").then((res) => {
                this.mapAll = res.data.all;
            });
        },
        // 获取省
        getProvince() {
            this.$util.post("/address/all").then((res) => {
                for (let key in res.data) {
                    this.provinceList.push(res.data[key]);
                    this.shopProList.push(res.data[key]);
                }
            });
        },
        init() {
            //需求
            this.$util.post('/user/otherOrder', {
                uid: this.id,
            }).then(res => {
                this.needList = res.data
                console.log(this.needList);
            })
            // 店铺
            // this.$util.post('/user/otherShopGoods', {
            //     uid: this.id,
            // }).then(res => {
            //     console.log(res);
            //     this.goodShopDefList = res.data
            // })
            this.$util.post('/user/otherInfo', {
                uid: this.id,
            }).then(res => {
                console.log(res);
                this.otherInfo = res.data[0]

            })
        },

    },
};
</script>

<style lang="scss" scoped>
// .main {
//     background: #f8f8f8;
// }
.tags {
    width: 1200px;
    margin: 0 auto;
    transform: translate(0, -100%);
    div {
        margin-left: 100px;
    }
    span {
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        color: #666666;
        margin-right: 20px;
    }
    .active {
        color: #e40012;
    }
}
.step {
    width: 100%;
    height: 42px;
    background: #515151;
    .step-c {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        line-height: 42px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        img {
            width: 17px;
            height: 17px;
        }
    }
}
.banner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 311px;
    background: url(../../assets/images/community/banner.jpg) no-repeat;
    .banner-c {
        width: 1200px;
        margin: 70px auto 0;
        display: flex;
        align-items: center;
        .portrait {
            width: 120px;
            height: 120px;
            background: #ffffff;
            border: 1px solid #707070;
            border-radius: 50%;
            opacity: 1;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .right {
            padding-left: 23px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            .bottom {
                padding-top: 18px;
                font-size: 18px;
                display: flex;
                .list {
                    margin-right: 45px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 16px;
                        height: 19px;
                    }
                }
            }
        }
    }
    .bt {
        width: 100%;
        height: 50px;
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        bottom: 0;
        .bt-c {
            font-size: 22px;
            color: #2ea7e0;
            font-family: Microsoft YaHei;
            font-weight: 400;

            padding-left: 500px;
            line-height: 50px;
            span {
                font-size: 18px;
                color: #222222;
            }
        }
    }
}
.center {
    padding-top: 50px;
    background: #f8f8f8;

    .center-c {
        padding: 20px;
        width: 1200px;
        background: #ffffff;
        margin: 0 auto;
        overflow: hidden;
        .title {
            height: 40px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            span {
                padding: 10px;
            }
            .active {
                color: #e40012;
                width: 81px;
                height: 0px;
                border-bottom: 2px solid #e40012;
            }
        }
        .line {
            width: 1160px;
            height: 0px;
            border: 1px solid #f1f1f1;
            margin-bottom: 20px;
        }
        .classification-content {
            width: 1200px;
            margin: 0 auto 65px;
            padding: 20px 20px 0;
            background: #fff;
            .litle-need {
                display: flex;
                flex-wrap: wrap;
                .litle-cell {
                    cursor: pointer;
                    margin-right: 12px;
                    background: #fffafa;
                    width: 245px;
                    padding: 30px 20px 30px;

                    margin-bottom: 20px;
                    border: 1px solid #fde1e2;
                    .litle-title {
                        font-size: 24px;
                        color: #222;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .tag-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // flex-wrap: wrap;
                        margin: 20px 0 24px;
                        .tag {
                            padding: 4px 8px;
                            max-width: 60px;
                            color: #222;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            background: #e3e3e3;
                            // margin-right: 10px;
                            border-radius: 4px;
                        }
                    }
                    .litle-money {
                        font-size: 18px;
                        color: #e40012;
                    }
                }
            }
        }
        .tips {
            display: flex;
            align-items: center;
            width: 200px;
            margin: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;

            color: #ff6600;
        }
    }
}
</style>